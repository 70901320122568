/*  400 (Regular) */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Regular.otf');
  font-weight: 400;
}

/*  500 (Medium) Regular */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Regular.otf'); 
  font-weight: 500;
}

/*  600 (Semi-Bold) Bold */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Bold.otf');
  font-weight: 600;
}

/*  700 (Bold) */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Bold.otf');
  font-weight: 700;
}

/*  800 (Extra-Bold أو Black) Black */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Black.otf'); 
  font-weight: 800;
}

/*  900 (Black) */
@font-face {
  font-family: 'TT Drugs Trial';
  src: url('./assets/fonts/tt_drugs/TT Drugs Trial Black.otf');
  font-weight: 900;
}


body {
  margin: 0;
  font-family: 'TT Drugs Trial',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: 'TT Drugs Trial', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
